import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";

import { reducer as userReducer } from "./user";
import { reducer as unionReducer } from "./union";
import { reducer as blockchainBrowserReducer } from "./blockchain-browser";

const reducer = combineReducers({
  user: userReducer,
  union: unionReducer,
  browser: blockchainBrowserReducer
});

export function initStore(initialState?: any) {
  return createStore(reducer, { ...initialState }, applyMiddleware(thunkMiddleware));
}
