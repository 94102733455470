/**
 * 派发自定义logout事件给document元素
 */
export const dispatchLogoutEvent = () => {
  let _event = new Event("logout");
  document.dispatchEvent(_event);
};

type EventType = "logout" | "websocketChange" | "ideSave";

class ICustomEvent {
  static emit = (eventStr: EventType, payload?: any, target = document) => {
    const event = new CustomEvent(eventStr, {
      detail: payload
    });
    target.dispatchEvent(event);
  };
}

export default ICustomEvent;
