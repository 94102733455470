const TOKEN_KEY = "token";
const USER_KEY = "currentUser";

/**
 * 缓存token
 *
 * @param token
 */
export const saveToken = (token: string) => {
  if (token) {
    localStorage.setItem(TOKEN_KEY, token);
  }
};

/**
 * 从缓存中获取token
 */
export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY) || undefined;
};

/**
 * 从缓存中移除token
 */
export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

/**
 * 缓存用户信息下
 *
 * @param user
 */
export const saveCurrentUser = (user: any) => {
  if (user) {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }
};

/**
 * 从缓存中获取当前用户信息
 */
export const getCurrentUser = () => {
  const str = localStorage.getItem(USER_KEY);
  return str ? JSON.parse(str) : undefined;
};

/**
 * 从缓存中移除当前用户信息
 */
export const removeCurrentUser = () => {
  localStorage.removeItem(USER_KEY);
};
