import { notification } from "antd";
import axios, { AxiosRequestConfig, AxiosStatic } from "axios";
import nprogress from "nprogress";
import * as Auth from "./auth";
nprogress.configure({ showSpinner: false });

const codeMessage: Record<number, string> = {
  200: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  204: "删除数据成功。",
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  401: "用户没有权限",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。"
};

/**
 * 异常处理程序
 */
const errorHandler = (error: any) => {
  nprogress.done();
  const { response } = error;

  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;

    /// 401 错误码强制用户重新登录
    /// 否则弹出错误信息
    if (response.status === 401) {
      Auth.removeToken();
      setTimeout(() => {
        window.location.replace("/login");
      }, 1000);
    } else {
      notification.error({
        message: `${response.status}:请求错误`,
        description: errorText
      });
    }
  } else if (!response) {
    notification.error({
      description: "您的网络发生异常，无法连接服务器",
      message: "网络异常"
    });
  }
  return Promise.reject(error ? error.toString() : "未知错误");
};

/**
 * ----------------------------------
 *  axios配置
 * ----------------------------------
 */

// 环境切换
// if (process.env.NODE_ENV == 'development') {
//     axios.defaults.baseURL = 'https://api.dev.com';
// } else if (process.env.NODE_ENV == 'test') {
//     axios.defaults.baseURL = 'https://api.test.com';
// } else if (process.env.NODE_ENV == 'production') {
//     axios.defaults.baseURL = 'https://api.prod.com';
// }

// 请求超时时间
axios.defaults.timeout = 10000;

// 设置post的请求头为application/x-www-form-urlencoded;charset=UTF-8
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    nprogress.start();

    // 为请求头增加token
    const authToken = Auth.getToken();
    if (authToken) {
      config.headers.Authorization = `bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    nprogress.done();
    return Promise.reject(error ? error.toString() : "发生错误");
  }
);

// 响应拦截器
axios.interceptors.response.use((response) => {
  nprogress.done();
  // const data: IResponse<{user: number}> = response.data;

  // const {status, message, user} = data;

  // console.log('data:',data);
  return Promise.resolve(response.data);
}, errorHandler);

// 声明响应数据格式
type IResponse<T> = {
  status: number;
  message: string;
  [key: string]: any;
} & T;

interface IAxios extends AxiosStatic {
  get<T = any, R = IResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R>;
  post<T = any, R = IResponse<T>>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
}

export default axios as IAxios;
