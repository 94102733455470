import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ContextMenu, ContextMenuTrigger, MenuItem } from "react-contextmenu";
import { Flex } from "..";
import Icon from "../Icon";
import styles from "./index.module.scss";
import "./contextmenu.css";

export type FileSystem = {
  name: string;
  path: string;
  size: number;
  extension: string;
  type: "directory" | "file";
  children?: FileSystem[];
};

type DirectoryTreeProps = {
  treeData: FileSystem;
  selectedPath?: string;
  expandedKeys?: string[];
  onSelect: (item: FileSystem) => void;
  onDelete?: (item: FileSystem) => void;
  onRename?: (item: FileSystem) => void;
  onAddFile?: (item?: FileSystem) => void;
  onAddDir?: (item?: FileSystem) => void;
};

const DirectoryTree: React.FC<DirectoryTreeProps> = (props) => {
  const { treeData, onSelect, selectedPath, onAddFile, onAddDir, onDelete, onRename, expandedKeys = [] } = props;

  const dataSource = useMemo(() => {
    // 目录排序函数， 文件夹在上，按字母排序
    function compare(a: FileSystem, b: FileSystem) {
      const aFirstChart = a.name.charAt(0);
      const bFirstChart = b.name.charAt(0);

      // 都是文件夹
      if ((a.type === "directory" && b.type === "directory") || (a.type === "file" && b.type === "file")) {
        return aFirstChart > bFirstChart ? -1 : 1;
      } else {
        return a.type === "directory" ? -1 : 1;
      }
    }
    /// 递归函数
    function recursive(item: FileSystem) {
      let result: FileSystem = { ...item };
      if (item.type === "directory" && item.children && item.children.length > 0) {
        result.children = item.children
          // 去除小数点开头的文件
          .filter((item) => !item.name.startsWith("."))
          // 排序
          .sort(compare)
          // 递归
          .map(recursive);
      }
      return result;
    }

    let _treeData: FileSystem = recursive(treeData);
    return _treeData;
  }, [treeData]);

  return (
    <div className={styles.root}>
      {dataSource.children &&
        dataSource.children.map((item) => {
          return (
            <DirectoryTreeItem
              key={item.path}
              selectedPath={selectedPath}
              item={item}
              indent={1}
              expanded={expandedKeys.includes(item.path)}
              expandedKeys={expandedKeys}
              onClick={onSelect}
              onDelete={(item) => {
                onDelete && onDelete(item);
              }}
              onRename={(item) => {
                onRename && onRename(item);
              }}
              onAddDir={(item) => {
                onAddDir && onAddDir(item);
              }}
              onAddFile={(item) => {
                onAddFile && onAddFile(item);
              }}
            />
          );
        })}
    </div>
  );
};

type DirectoryTreeItemProps = {
  item: FileSystem;
  expanded?: boolean;
  indent?: number;
  expandedKeys?: string[];
  onClick: (item: FileSystem) => void;
  onDelete: (item: FileSystem) => void;
  onRename: (item: FileSystem) => void;
  onAddFile: (item: FileSystem) => void;
  onAddDir: (item: FileSystem) => void;
  selectedPath?: string;
};

const DirectoryTreeItem: React.FC<DirectoryTreeItemProps> = ({
  item,
  indent = 0,
  onClick,
  onRename,
  onDelete,
  onAddFile,
  onAddDir,
  selectedPath,
  expanded,
  expandedKeys = []
}) => {
  const [active, setActive] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // 他处点击检测
    // 如果点击的不是header，则设置active=false
    function otherClickCheck(e: MouseEvent) {
      const dom = ref.current;
      if (dom && !dom.contains(e.target as any)) {
        setActive(false);
      }
    }

    document.addEventListener("click", otherClickCheck, { passive: true });
    document.addEventListener("contextmenu", otherClickCheck, { passive: true });
    return () => {
      document.removeEventListener("click", otherClickCheck);
      document.removeEventListener("contextmenu", otherClickCheck);
    };
  }, []);

  const classes = useMemo(() => {
    const _cls = [styles.item];
    if (selectedPath === item.path) {
      _cls.push(styles["item-selected"]);
    }
    if (active) {
      _cls.push(styles["item-right-click"]);
    }
    return _cls;
  }, [item.path, selectedPath, active]);

  const handleClick = useCallback(() => {
    onClick(item);
  }, [item, onClick]);

  return (
    <div className={classes.join(" ")}>
      <ContextMenuTrigger id={item.path}>
        <div
          ref={ref}
          className={styles["item-row"]}
          style={{ paddingLeft: `${indent * 12}px` }}
          onClick={handleClick}
          onContextMenu={() => setActive(true)}
        >
          <Flex>
            <div style={{ width: 24 }}>
              {item.type === "directory" && (
                <Flex align="center" jusify="center">
                  <Icon>{expanded ? "xiangxia2" : "xiangyou1"}</Icon>
                </Flex>
              )}
            </div>
            <Flex.Item>
              <span className={styles["item-label"]}>{item.name}</span>
            </Flex.Item>
          </Flex>
        </div>
      </ContextMenuTrigger>
      {item.type === "directory" && item.children && expanded && (
        <div className={styles["item-children"]}>
          {item.children.map((child) => (
            <DirectoryTreeItem
              key={child.path}
              indent={indent + 1}
              selectedPath={selectedPath}
              expanded={expandedKeys.includes(child.path)}
              expandedKeys={expandedKeys}
              item={child}
              onClick={onClick}
              onDelete={onDelete}
              onRename={onRename}
              onAddDir={onAddDir}
              onAddFile={onAddFile}
            />
          ))}
        </div>
      )}

      <ContextMenu id={item.path}>
        {item.type === "directory" && (
          <>
            <MenuItem onClick={() => onAddFile(item)}>新建文件</MenuItem>
            <MenuItem onClick={() => onAddDir(item)}>新建文件夹</MenuItem>
          </>
        )}
        <MenuItem onClick={() => onRename(item)}>重命名</MenuItem>
        <MenuItem onClick={() => onDelete(item)}>删除</MenuItem>
      </ContextMenu>
    </div>
  );
};

export default DirectoryTree;
