import React from "react";
import ReactDOM from "react-dom";
import BlockHeader from "./BlockHeader";
import Container from "./Container";
import ProTable from "./ProTable";
import Loading from "./Loading";
import Welcome from "./Welcome";
import Icon from "./Icon";
import Flex from "./Flex";
import Box from "./Box";
import DirectoryTree from "./DirectoryTree";
import ImageCropperModal, { ImageCropperModalProps } from "./ImageCropperModal";
import { destoryModal } from "..";

export { Container, Welcome, Flex, Box, Loading, Icon, ImageCropperModal, BlockHeader, ProTable, DirectoryTree };

let imageCropperModalDiv: Component.ModalContainer;
/**
 * 函数式调用图片裁剪modal
 *
 * @param props
 */
export const imageCropper = (props: ImageCropperModalProps) => {
  const _props: ImageCropperModalProps = {
    ...props,
    visible: true,
    afterClose: () => destoryModal(imageCropperModalDiv)
  };
  imageCropperModalDiv = document.createElement("div");
  ReactDOM.render(<ImageCropperModal {..._props} />, imageCropperModalDiv);
};
