import { notification } from "antd";
import { dispatchLogoutEvent } from "./customEvent";

interface MessageType {
  action: string;
  payload?: any;
}

class WebSocketServer {
  instance?: WebSocket;
  status: string;
  url: string;
  protocols?: string | string[];
  pingPong?: string;
  pingInterval?: any;
  pongInterval?: any;

  constructor(url: string, protocols?: string | string[]) {
    this.status = "init";
    this.url = url;
    this.protocols = protocols;
    this.connect();
  }

  connect() {
    this.instance = new WebSocket(this.url, this.protocols);
    this.instance.onopen = this.onOpen;
    this.instance.onerror = this.onError;
    this.instance.onclose = (e: CloseEvent) => {
      if (e.code === 1001) {
        // message.warn(`此账号在另外一个地方登录`);
        notification.warning({
          message: "安全警告",
          description: "此账号在另外一个地方登录，如非您本人操作，请尽快修改密码。",
          type: "warning",
          duration: 12
        });
        dispatchLogoutEvent();
        this.close();
      }
    };
    this.onMessage();
  }

  onOpen = () => {
    this.status = "open";
    console.info("WebSocket Client Connected");
    this.connectCheck();
  };

  onError = (event: Event) => {
    this.status = "error";
    console.error("WebSocket Error:", event);
  };

  onMessage = (cb?: (data: any) => void) => {
    if (!this.instance) return;
    this.instance.onmessage = (e) => {
      this.pingPong = "pong"; // 收到消息，说明连接还有效
      cb && cb(e.data);
    };
  };

  sendMessage = (message: MessageType | string) => {
    if (!this.instance || this.status !== "open") return;
    this.instance.send(JSON.stringify(message));
  };

  getReadyState = () => {
    if (!this.instance) return;
    return this.instance.readyState;
  };

  closeHandle = (e = "err") => {
    // 因为webSocket并不稳定，规定只能手动关闭(调close方法)，否则就重连
    if (this.status !== "close") {
      console.info(`断开，重连websocket。${e}`);
      if (this.pingInterval !== undefined && this.pongInterval !== undefined) {
        // 清除定时器
        clearInterval(this.pingInterval);
        clearInterval(this.pongInterval);
      }
      this.connect(); // 重连
    } else {
      console.info(`websocket手动关闭,或者正在连接`);
    }
  };

  // 连接检测，防止链接意外关闭
  connectCheck = () => {
    this.pingPong = "ping"; // instance的心跳机制状态值
    this.pingInterval = setInterval(() => {
      if (this.getReadyState() === 1) {
        // 检查instance为链接状态 才可发送
        this.sendMessage({ action: "ping" }); // 客户端发送ping
      }
    }, 10000);

    this.pongInterval = setInterval(() => {
      if (this.pingPong === "ping") {
        this.closeHandle("pingPong没有改变为pong"); // 没有返回pong 重启webSocket
      }
      // 重置为ping 若下一次 ping 发送失败 或者pong返回失败(pingPong不会改成pong)，将重启
      this.pingPong = "ping";
    }, 20000);
  };

  close = () => {
    if (this.pingInterval) clearInterval(this.pingInterval);
    if (this.pongInterval) clearInterval(this.pongInterval);
    if (!this.instance) return;
    this.status = "close";
    this.sendMessage({ action: "close" });
    this.instance.close();
    console.info("WebSocket Client Colsed");
  };
}

export default WebSocketServer;
