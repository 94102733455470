import React from "react";
import { Link, Redirect, Route } from "react-router-dom";
import * as Auth from "@/libs/auth";
import { IRouteType } from "@/routes";
import { Breadcrumb } from "antd";

const defaultTitle: string = "BaaS平台";

/**
 * 渲染路由对应的组件
 *
 * @param route
 * @param props
 */
const handleRouteMap = (route: IRouteType, index: number) => {
  return (
    <Route
      key={index}
      path={route.path}
      exact={route.exact}
      render={(props) => {
        if (route.noAuth) {
          return <route.component props={props} />;
        }
        const token = Auth.getToken();
        if (token) {
          // 动态更改页面title
          document.title = `${route.title || defaultTitle}-碧珠`;
          return <route.component {...props} routes={route.children} redirect={route.redirect} />;
        }
        // 如果没有token，重定向到登录页面
        return (
          <Redirect
            to={{
              pathname: "/login",
              search: `redirect=${encodeURIComponent(window.location.href)}`
            }}
          />
        );
      }}
    />
  );
};

const createBreadcrumb = (breadcrumbs: { path?: string; name: string }[]) => {
  return (
    <Breadcrumb style={{ lineHeight: "32px" }}>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <Breadcrumb.Item key={index}>
            {breadcrumb.path ? <Link to={breadcrumb.path}>{breadcrumb.name}</Link> : breadcrumb.name}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default { handleRouteMap, createBreadcrumb };
