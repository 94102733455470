import React, { CSSProperties } from "react";
import { Spin } from "antd";

/**
 * ### 加载页面
 *
 * Props
 *
 * |参数 | 说明 | 类型 |默认值 |
 * | :- | :- | :- | :- |
 * | tip | 加载提示内容 | `string` | 加载中 |
 *
 */
const Loading: React.FC<{
  tip?: string;
  size?: "small" | "default" | "large";
  style?: CSSProperties;
}> = ({ tip = "加载中", size = "default", style }) => {
  const _style: CSSProperties = {
    width: "100%",
    textAlign: "center",
    marginTop: size === "large" ? "20vh" : size === "default" ? "32px" : "16px",
    ...style
  };

  return (
    <div style={_style}>
      <Spin size={size} tip={tip} />
    </div>
  );
};

export default Loading;
