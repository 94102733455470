import * as unionApi from "@/services/union";
import actions from "./actions";

/**
 * 获取联盟列表
 */
const getUnionList = () => async (dispatch: any) => {
  try {
    const { status, unions, message } = await unionApi.query();
    if (status === 200) {
      dispatch(actions.setUnionList(unions));
    } else {
      return Promise.reject(message);
    }
    return Promise.resolve();
  } catch (error) {
    return Promise.resolve(error);
  }
};

/**
 * 获取当前联盟信息
 * TODO: 当前是模拟获取
 *
 * @param unionId 联盟ID
 */
const getCurrentUnion = (unionId: string) => async (dispatch: any, state: any) => {
  const unionList: DPBaaS.Union[] = state().union.unionList;
  if (!unionList || unionList.length <= 0) {
    return Promise.reject("store中未获取到unionList");
  }
  const find = unionList.find((union) => union.id === unionId);
  if (find) {
    dispatch(actions.setCurrentUnion(find));
  } else {
    return Promise.reject("未获取到对应的联盟信息");
  }
  return Promise.resolve();
};

export default {
  getUnionList,
  getCurrentUnion
};
