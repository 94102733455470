import * as types from "./types";

export interface BlockchainBrowserState {
  chain?: DPBaaS.Chain;
  network?: string;
  channels?: any[];
  channelGenesisHash?: string;
}

const initialState: BlockchainBrowserState = {};

const reducers = (state = initialState, action: any) => {
  console.log(action);

  switch (action.type) {
    case types.CHAIN:
      return {
        ...state,
        chain: action.payload
      };

    case types.NETWORK:
      return {
        ...state,
        network: action.payload
      };

    case types.CHANNEL_GENESIS_HASH:
      return {
        ...state,
        channelGenesisHash: action.payload
      };

    case types.CHANNELS:
      return {
        ...state,
        channels: action.payload
      };

    default:
      return state;
  }
};

export default reducers;
