/**
 * 系统内置对象原型扩展
 */
/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/

Date.prototype.Format = function (fmt: string = "yyyy-MM-dd") {
  var o: any = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  }
  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
    }
  }
  return fmt;
};

Date.prototype.Distance = function () {
  var distance: string = "";
  var that = this; // 目标时间
  var now = new Date(); // 当前时间
  const diff = (now.getTime() - that.getTime()) / 1000; // 间隔秒数
  if (diff < 60) {
    distance = "刚刚";
  } else if (diff < 60 * 10) {
    distance = `${now.getMinutes() - that.getMinutes()}分钟前`;
  } else if (diff < 60 * 60 * 24) {
    if (that.getDate() === now.getDate()) {
      let hour = that.getHours();
      let min = that.getMinutes();
      distance = `${hour > 12 ? "下午" : "上午"} ${hour % 12}:${min >= 10 ? min : "0" + min}`;
    } else {
      distance = "1天前";
    }
  } else if (diff < 60 * 60 * 24 * 7) {
    distance = Math.floor(diff / (60 * 60 * 24)) + "天前";
  } else {
    if (that.getFullYear() === now.getFullYear()) {
      distance = `${that.getMonth() + 1}月${that.getDate()}日`;
    } else {
      distance = `${that.getFullYear()}年${that.getMonth() + 1}月${that.getDate()}日`;
    }
  }
  return distance;
};

export default {};
