import React, { CSSProperties } from "react";
import Icon from "../Icon";
import styles from "./index.module.scss";

type BlockHeaderProps = {
  title: string;
  onClick?: () => void;
  style?: CSSProperties;
};

const BlockHeader: React.FC<BlockHeaderProps> = ({ title, onClick, style }) => {
  return (
    <div className={`${styles.root} ${onClick ? styles.linked : ""}`} style={style} onClick={onClick}>
      <h2 className={styles.title}>{title}</h2>

      {onClick && (
        <div className={styles.arrow}>
          <Icon>xiangyou1</Icon>
        </div>
      )}
    </div>
  );
};
export default BlockHeader;
