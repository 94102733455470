import * as types from "./types";

export interface ChainState {
  unionList: DPBaaS.Union[];
  currentUnion?: DPBaaS.Union;
}

const initialState: ChainState = {
  unionList: []
};

const chainReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.UNION_LIST:
      return {
        ...state,
        unionList: action.payload
      };
    case types.CURRENT_UNION:
      return {
        ...state,
        currentUnion: action.payload
      };

    default:
      return state;
  }
};

export default chainReducers;
