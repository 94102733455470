import ReactDOM from "react-dom";

/**
 * 销毁Modal容器
 *
 * @param div 要销毁的容器dom
 */
const destoryModal = (div: Component.ModalContainer) => {
  if (!div) return;
  const unmountDom = ReactDOM.unmountComponentAtNode(div);
  if (unmountDom && div.parentNode) {
    div.parentNode.removeChild(div);
    div = null;
  }
};

export { destoryModal };
