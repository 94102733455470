import * as types from "./types";

const setUnionList = (unionList?: DPBaaS.Union[]) => ({
  type: types.UNION_LIST,
  payload: unionList
});

const setCurrentUnion = (currentUnion?: DPBaaS.Union) => ({
  type: types.CURRENT_UNION,
  payload: currentUnion
});

export default {
  setUnionList,
  setCurrentUnion
};
