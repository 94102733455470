import { notification } from "antd";
import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import WebSocketServer from "@/libs/websocket";
import { NoticeType } from "@/libs/notice";
import componentLibs from "@/libs/components";
import { Loading } from "@/components/commons";

import { actions, operations } from "@/state/user";
import { wsUrl } from "./config";
import ICustomEvent from "./libs/customEvent";
import routes from "./routes";

export let ws: WebSocketServer;

const DefaultLayout = lazy(() => import("./layouts"));
const PageNotFound = lazy(() => import("./pages/404"));
const LoginPage = lazy(() => import("./pages/user/login"));
const RegisterPage = lazy(() => import("./pages/user/register"));
const ResetPasswordPage = lazy(() => import("./pages/user/reset-password"));
const IDEPage = lazy(() => import("./pages/IDE"));

const App: React.FC<{ token: string; setUnreadNotice: (num: number) => void; updateUnreadNotice: () => void }> = (
  props
) => {
  const { token, setUnreadNotice, updateUnreadNotice } = props;
  const dispatch = useDispatch();
  const [appIsActive, setAppIsActive] = useState(true);

  const startWS = useCallback(() => {
    console.log("_token:", token);

    if (!token) {
      endWS();
      return;
    }

    updateUnreadNotice();

    ws = new WebSocketServer(wsUrl, ["token", token.trim()]);

    ws.onMessage((data: any) => {
      if (!data) return;
      ICustomEvent.emit("websocketChange", data);
      try {
        const { action, params } = JSON.parse(data);
        switch (action) {
          case NoticeType.SEND_NOTICE:
            notification.info({
              message: params.title,
              description: params.content
            });
            break;
          case NoticeType.SEND_NOTICE_UNREAD:
            if (typeof params.count != "undefined") {
              dispatch(setUnreadNotice(params.count));
            }
            break;
        }
      } catch (error) {
        console.error(error);
      }
    });
  }, [dispatch, setUnreadNotice, token, updateUnreadNotice]);

  const endWS = () => {
    ws && ws.close();
  };

  useEffect(() => {
    console.log("appIsActive:", appIsActive);

    if (appIsActive) {
      startWS();
    } else {
      endWS();
    }
  }, [startWS, appIsActive]);

  // 监听logout事件，注销当前登录
  useEffect(() => {
    function handleLogout() {
      dispatch(operations.logout());
    }

    document.addEventListener("logout", handleLogout, { passive: true });

    return () => {
      document.removeEventListener("logout", handleLogout);
    };
  }, [dispatch]);

  // 程序退出时关闭连接
  useEffect(() => {
    return endWS;
  }, []);

  useEffect(() => {
    function visibilitychange(e: Event) {
      const isActive = !document.hidden;
      localStorage.setItem("appIsActive", isActive ? "1" : "0");
      setAppIsActive(isActive);
    }

    document.addEventListener("visibilitychange", visibilitychange);
  }, []);

  return (
    <Router>
      <Suspense fallback={<Loading size="large" />}>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegisterPage} />
          <Route path="/resetpwd" component={ResetPasswordPage} />
          <Route path="/contract/:contract_id/ide" component={IDEPage} />
          <DefaultLayout>
            <Suspense fallback={<Loading size="large" />}>
              <Switch>
                {routes.map(componentLibs.handleRouteMap)}
                <Redirect from="/union/:union_id" to="/union/:union_id/chain" exact />
                <Redirect from="/openchain" to="/openchain/overview" exact />
                <Redirect from="/" to="/home" exact />
                <Route component={PageNotFound} />
              </Switch>
            </Suspense>
          </DefaultLayout>
        </Switch>
      </Suspense>
    </Router>
  );
};

const mapStateToProps = (state: any) => ({
  token: state.user.authToken
});

const mapDispatchToProps = {
  setUnreadNotice: actions.setUnreadNotice,
  updateUnreadNotice: operations.updateUnreadNotice
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
