/**
 * 二次封装antd表格组件
 *
 */
import { Table } from "antd";
import { TableProps } from "antd/lib/table";
import React from "react";
import styles from "./index.module.scss";
import { Flex } from "..";
import Box from "../Box";

type ProTableProps = {
  tableName?: string;
  extra?: React.ReactNode;
} & TableProps<any>;

const ProTable: React.FC<ProTableProps> = (props) => {
  const { tableName, extra, loading } = props;
  return (
    <Box round>
      <div className={styles.root}>
        <Flex jusify="between" className={styles.header}>
          <div className={styles.title}>{tableName}</div>
          {extra && <div className={styles.extra}>{extra}</div>}
        </Flex>
        <Table {...props} loading={loading} />
      </div>
    </Box>
  );
};
export default ProTable;
