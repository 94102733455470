import { useEffect } from "react";

export const useRouteChange = (call: () => void) => {
  useEffect(() => {
    const handleRouteChange = () => {
      call();
    };

    window.addEventListener("popstate", handleRouteChange, { passive: true });
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, [call]);
};
