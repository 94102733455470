/**
 * Flex布局组件
 *
 */
import React from "react";
import "./index.scss";

type FlexProps = {
  align?: "start" | "center" | "end";
  jusify?: "start" | "center" | "end" | "between" | "stretch";
  direction?: "row" | "column";
  wrap?: boolean;
} & Component.CommonProps;

type FlexItemProps = Component.CommonProps;

const Flex: React.FC<FlexProps> & {
  Item: React.FC<FlexItemProps>;
} = (props) => {
  const { children, className, style, align, jusify, direction = "row", wrap = false } = props;
  const { onClick } = props;

  let _classes = `flex flex-direction-${direction}`;
  if (align) _classes += ` flex-align-${align}`;
  if (jusify) _classes += ` flex-justify-${jusify}`;
  if (wrap) _classes += ` flex-wrap`;

  if (className) _classes += ` ${className}`;
  return (
    <div className={_classes} style={style} onClick={onClick}>
      {children}
    </div>
  );
};

const FlexItem: React.FC<FlexItemProps> = (props) => {
  const { children, style, className } = props;

  const { onClick } = props;
  let _classes = "flex-item";
  if (className) _classes += ` ${className}`;
  return (
    <div className={_classes} style={style} onClick={onClick}>
      {children}
    </div>
  );
};

Flex.Item = FlexItem;

export default Flex;
