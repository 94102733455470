/**
 * 图片裁剪组件
 */
import { Modal } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import { useRouteChange } from "@/hooks/useRouteChange";

export type ImageCropperModalProps = {
  image: File;
  onCrop: (blob: Blob) => void;
} & Component.ModalProps;

const ImageCropperModal: Component.ModalComponent<ImageCropperModalProps> = (props) => {
  const { image, onCrop, afterClose } = props;
  const imageRef = useRef<HTMLImageElement>(null);
  const [visible, setVisible] = useState(props.visible || false);
  const [cropper, setCropper] = useState<Cropper>();

  useRouteChange(() => setVisible(false));

  const initCropper = (elment: HTMLImageElement) => {
    const _cropper = new Cropper(elment, {
      aspectRatio: 1,
      viewMode: 3,
      minCropBoxHeight: 120,
      minCropBoxWidth: 120,
      scalable: false,
      zoomable: false
    });
    setCropper(_cropper);
  };

  useEffect(() => {
    var reads = new FileReader();
    reads.readAsDataURL(image);
    reads.onload = function () {
      if (imageRef.current && this.result) {
        imageRef.current.src = this.result as string;
        initCropper(imageRef.current);
      }
    };
  }, [image]);

  const handleCropper = useCallback(() => {
    if (cropper) {
      cropper
        .getCroppedCanvas({
          width: 256,
          height: 256
        })
        .toBlob((blob: Blob | null) => {
          if (blob) {
            onCrop(blob);
            setVisible(false);
          }
        });
    }
  }, [cropper, onCrop]);

  return (
    <Modal
      visible={visible}
      width={560}
      destroyOnClose
      afterClose={afterClose}
      title="图片裁剪"
      cancelText="取消"
      okText="裁剪"
      closable={false}
      maskClosable={false}
      onOk={handleCropper}
      onCancel={() => setVisible(false)}
    >
      <div style={{ width: "100%", height: 400, overflow: "hidden" }}>
        <img ref={imageRef} alt="box" style={{ maxWidth: "100%" }} />
      </div>
    </Modal>
  );
};

export default ImageCropperModal;
