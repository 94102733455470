import * as types from "./types";

const setChain = (chain?: DPBaaS.Chain) => ({
  type: types.CHAIN,
  payload: chain
});

const setNetwork = (network?: string) => ({
  type: types.NETWORK,
  payload: network
});

const setChannels = (channels?: any[]) => ({
  type: types.CHANNELS,
  payload: channels
});

const setChannelGenesisHash = (genesisHash: string) => ({
  type: types.CHANNEL_GENESIS_HASH,
  payload: genesisHash
});

export default {
  setChain,
  setNetwork,
  setChannels,
  setChannelGenesisHash
};
