import React, { CSSProperties } from "react";
import styles from "./index.module.scss";

/**
 * ### Container 容器组件
 *
 * - 约束子元素的最大宽度
 * - 响应式内边距
 */
const Container: React.FC<{ style?: CSSProperties; className?: string }> = ({ children, style, className }) => {
  return (
    <div className={`${styles.root}${className ? " " + className : ""}`} style={style}>
      {children}
    </div>
  );
};
export default Container;
