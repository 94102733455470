import http from "@/libs/http";

/**
 * 创建联盟链
 *
 * @param data
 */
export const create = (data: DPBaaS.CreateChainParams) => {
  return http.post<{ chain: DPBaaS.Chain }>("/sys/chain/create", data);
};

/**
 * 查询联盟下的链列表
 */
export const query = (unionId: string) => {
  return http.get<{ chains: DPBaaS.Chain[] }>(`/sys/union/${unionId}/chains`);
};

/** 获取联盟链可选配置信息 */
export const queryPreference = () => {
  return http.get<{ preferences: DPBaaS.ChainPreferenceType[] }>("/sys/pref/list");
};

/** 启动链 */
export const start = (chainId: string) => {
  return http.post("/sys/chain/start", { chainId });
};

/** 停止链 */
export const stop = (chainId: string) => {
  return http.post("/sys/chain/stop", { chainId });
};

/**
 * 安装合约到链上
 *
 * @param chainId
 * @param contractId
 */
export const installContract = (chainId: string, contractId: string) => {
  return http.post(
    `/sys/chain/installContract`,
    {
      chainId,
      contractId
    },
    {
      timeout: 600000
    }
  );
};

/**
 * 从链上卸载合约
 *
 * @param chainId
 * @param contractId
 */
export const uninstallContract = (chainId: string, contractId: string) => {
  return http.post(`/sys/chain/uninstallContract`, {
    chainId,
    contractId
  });
};

/**
 * 安装应用到链上
 *
 * @param chainId
 * @param applicationId
 */
export const installApplication = (chainId: string, applicationId: string) => {
  return http.post(`/sys/chain/installApplication`, {
    chainId,
    applicationId
  });
};

/**
 * 从链上卸载应用
 *
 * @param chainId
 * @param applicationId
 */
export const uninstallApplication = (chainId: string, applicationId: string) => {
  return http.post(`/sys/chain/uninstallApplication`, {
    chainId,
    applicationId
  });
};

/**
 * 根据链的网络名称获取通道信息
 *
 * @param network 网络名称（链的host）
 */
export const getChannelInfo = (network: string) => {
  return http.get(`/api/network/channels/${network}`);
};

export type ChannelConfig = {
  network: string;
  genesisHash: string;
};

/**
 * 获取网络状态
 *
 * @param network 网络名称
 * @param genesisHash 通道hash
 */
export const getNetStatus = (config: ChannelConfig) => {
  return http.get(`/api/status/${config.network}/${config.genesisHash}`);
};

/**
 *根据块号获取块的所有交易信
 
 * @param network 
 * @param genesisHash 
 * @param blockNumber 
 */
export const getTransactionsByBlocknum = (blocknum: string | number, config: ChannelConfig) => {
  return http.get(`/api/block/transactions/${config.network}/${config.genesisHash}/${blocknum}`);
};

/**
 * 根据交易id获取交易的信息
 *
 * @param txid
 * @param config
 */
export const getTransactionInfoByTxid = (txid: string, config: ChannelConfig) => {
  return http.get(`/api/transaction/${config.network}/${config.genesisHash}/${txid}`);
};

/**
 * 根据块号获取块的信息
 *
 * @param blocknum
 * @param config
 */
export const getBlockInfoByBlocknum = (blocknum: string, config: ChannelConfig) => {
  return http.get(`/api/blockByNumber/${config.network}/${config.genesisHash}/${blocknum}`);
};

/**
 * 根据块高度获取块的信息
 *
 * @param blockhash
 * @param config
 */
export const getBlockInfoByBlockhash = (blockhash: string, config: ChannelConfig) => {
  return http.get(`/api/blockByHash/${config.network}/${config.genesisHash}/${blockhash}`);
};

/**
 * 综合搜索
 *
 * @param network
 */
export const search = (network: string, keyWord: string) => {
  return http.get(`/api/search/${network}?keyWord=${keyWord}`);
};

export type Paginate = {
  page: number;
  prePage: number;
};

/**
 * 查询区块
 *
 * @param config 通道信息
 * @param paginate 分页信息
 */
export const queryBlocks = (config: ChannelConfig, paginate: Paginate) => {
  return http.get<{ blocks: DPBaaS.Block[] }>(
    `/api/blocks/${config.network}/${config.genesisHash}?page=${paginate.page}&prePage=${paginate.prePage}`
  );
};

/**
 * 查询交易
 *
 * @param config
 * @param paginate
 */
export const queryTransactions = (config: ChannelConfig, paginate: Paginate) => {
  return http.get<{ transactions: DPBaaS.Transaction[] }>(
    `/api/transactions/${config.network}/${config.genesisHash}?page=${paginate.page}&prePage=${paginate.prePage}`
  );
};

/**
 * 区块折线图数据，返回小时的区块数量
 *
 * @param config 通道配置
 * @param hour 时间跨度：天
 */
export const queryBlocksByDay = (config: ChannelConfig, days = 30) => {
  return http.get(`/api/blocksByDay/${config.network}/${config.genesisHash}/${days}`);
};

/**
 * 区块折线图数据，返回小时的区块数量
 *
 * @param config 通道配置
 * @param hour 时间跨度：天
 */
export const queryBlocksByHour = (config: ChannelConfig, day = 1) => {
  return http.get(`/api/blocksByHour/${config.network}/${config.genesisHash}/${day}`);
};

/**
 * 区块折线图数据，返回每分钟的区块数量
 *
 * @param config 通道配置
 * @param hour 时间跨度：小时
 */
export const queryBlocksByMinute = (config: ChannelConfig, hour = 1) => {
  return http.get(`/api/blocksByMinute/${config.network}/${config.genesisHash}/${hour}`);
};

/**
 * 交易记录折线图数据，返回每小时的交易数量
 *
 * @param config 通道配置
 * @param day 时间跨度：天
 */
export const queryTxsByDay = (config: ChannelConfig, days = 30) => {
  return http.get(`/api/txByDay/${config.network}/${config.genesisHash}/${days}`);
};

/**
 * 交易记录折线图数据，返回每小时的交易数量
 *
 * @param config 通道配置
 * @param day 时间跨度：天
 */
export const queryTxsByHour = (config: ChannelConfig, day = 1) => {
  return http.get(`/api/txByHour/${config.network}/${config.genesisHash}/${day}`);
};

/**
 * 交易记录折线图数据，返回每分钟的交易数量
 *
 * @param config 通道配置
 * @param hour 时间跨度：小时
 */
export const queryTxsByMinute = (config: ChannelConfig, hour = 1) => {
  return http.get(`/api/txByMinute/${config.network}/${config.genesisHash}/${hour}`);
};

/**
 * 查询节点数据
 *
 * @param config 通道配置
 */
export const queryPeers = (config: ChannelConfig) => {
  return http.get(`/api/peers/${config.network}/${config.genesisHash}`);
};
