import http from "@/libs/http";

/**
 * 获取用户所有通知
 */
export function query(params?: {
  offset: number; // 开始位置
  pagesize: number; // 一页的大小
  subtype?: DPBaaS.NoticeSubType; // 通知二级分类
  status?: DPBaaS.NoticeStatus; // 通知状态
  unionId?: string;
  chainId?: string;
}) {
  return http.get<{ notices: DPBaaS.Notice[]; count: number }>("/sys/user/notices", { params });
}

/**
 * 获取用户所有未读通知
 */
export function queryUnread(params?: {
  offset: number; // 开始位置
  pagesize: number; // 一页的大小
}) {
  return http.get<{ notices: DPBaaS.Notice[]; count: number }>("/sys/user/unreadnotices", { params });
}

/**
 * 获取用户所有未读且紧要通知
 */
export function queryUrgent() {
  return http.get("/sys/user/urgentnotices");
}

/**
 * 获取联盟的紧要未读通知
 */
export function queryUrgentByUnion(
  unionId: string,
  params?: {
    offset: number; // 开始位置
    pagesize: number; // 一页的大小
  }
) {
  return http.get(`/sys/union/${unionId}/urgentnotices`, { params });
}

/**
 * 获取联盟链的紧要未读通知
 */
export function queryUrgentByChain(
  chainId: string,
  params?: {
    chainId: string[]; // 通知的id数组
    offset: number; // 开始位置
    pagesize: number; // 一页的大小
  }
) {
  return http.get(`/sys/chain/${chainId}/urgentnotices`, { params });
}

/**
 * 获取未读消息数量
 */
export function getUnreadCount() {
  return http.get("/sys/user/unreadcount");
}

/**
 * 设置通知为已读
 *
 * @param noticeIds
 */
export function setRead(noticeIds: string[]) {
  return http.post(`/sys/notice/read`, {
    noticeIds
  });
}

/**
 * 设置通知为未已读
 *
 * @param noticeIds
 */
export function setUnread(noticeIds: string[]) {
  return http.post(`/sys/notice/unread`, {
    noticeIds
  });
}

/**
 * 移除通知
 * 并不是真正的删除，只是把状态删除
 *
 * @param noticeIds
 */
export function remove(noticeIds: string[]) {
  return http.post(`/sys/notice/remove`, {
    noticeIds
  });
}
