import * as types from "./types";

const setUserInfo = (userInfo?: any) => ({
  type: types.USER_INFO,
  payload: userInfo
});

const setAuthToken = (token?: string) => ({
  type: types.AUTH_TOKEN,
  payload: token
});

const setUnreadNotice = (num: number) => ({
  type: types.UNREAD_NOTICE,
  payload: num
});

export default {
  setUserInfo,
  setAuthToken,
  setUnreadNotice
};
