import { lazy } from "react";

export type IRouteType = {
  // 页面path
  path: string;
  redirect?: string;
  // 页面对应组件
  component?: any;
  // 页面对应标题
  title?: string;
  // 短标题
  shotTitle?: string;
  // 精准匹配路由
  exact?: boolean;
  // 无需授权的页面
  noAuth?: boolean;
  children?: IRouteType[];
  // iconfont 名称
  icon?: string;
  hideInMenu?: boolean;
};

const routes: Array<IRouteType> = [
  {
    path: "/home",
    component: lazy(() => import("./pages/home")),
    title: "首页",
    shotTitle: "首页",
    icon: "home"
  },
  {
    path: "/union",
    component: lazy(() => import("./pages/union")),
    title: "我的联盟",
    shotTitle: "联盟",
    exact: true,
    icon: "union"
  },
  /** 联盟管理模块路由配置 */
  {
    path: "/union/:union_id",
    redirect: "/union/:union_id/chain",
    title: "联盟管理",
    hideInMenu: true,
    icon: "union",
    component: lazy(() => import("./pages/union/union-management")),
    children: [
      {
        path: "/union/:union_id/chain",
        component: lazy(() => import("./pages/union/children/chain")),
        title: "联盟内的链"
      },
      {
        path: "/union/:union_id/contract/create",
        component: lazy(() => import("./pages/union/children/createContract")),
        title: "创建合约工程",
        hideInMenu: true
      },
      {
        path: "/union/:union_id/contract",
        component: lazy(() => import("./pages/union/children/contract")),
        title: "合约工程"
      },
      {
        path: "/union/:union_id/cert",
        component: lazy(() => import("./pages/union/children/cert")),
        title: "证书管理"
      },
      {
        path: "/union/:union_id/app/union",
        component: lazy(() => import("./pages/union/children/application/unionApplication")),
        title: "联盟内共享应用"
      },
      {
        path: "/union/:union_id/app/platform",
        component: lazy(() => import("./pages/union/children/application/platformApplication")),
        title: "平台应用"
      },
      {
        path: "/union/:union_id/approve",
        component: lazy(() => import("./pages/union/children/orgAprove")),
        title: "审批组织"
      },
      {
        path: "/union/:union_id/org",
        component: lazy(() => import("./pages/union/children/org")),
        title: "邀请组织"
      }
    ]
  },
  /** 开放联盟链模块路由配置 */
  {
    path: "/openchain",
    redirect: "/openchain/overview",
    title: "开放联盟链",
    shotTitle: "公共链",
    icon: "open-chain",
    component: lazy(() => import("./pages/openchain")),
    children: [
      {
        path: "/openchain/overview",
        component: lazy(() => import("./pages/openchain/children/overview")),
        title: "总览"
      },
      {
        path: "/openchain/balance",
        component: lazy(() => import("./pages/openchain/children/balance")),
        title: "燃料管理"
      },
      {
        path: "/openchain/contract/create",
        component: lazy(() => import("./pages/openchain/children/createContract")),
        title: "创建合约工程",
        hideInMenu: true
      },
      {
        path: "/openchain/contract",
        component: lazy(() => import("./pages/openchain/children/contract")),
        title: "合约管理"
      },
      {
        path: "/openchain/account/add",
        component: lazy(() => import("./pages/openchain/children/account/add")),
        title: "添加链账户",
        hideInMenu: true
      },
      {
        path: "/openchain/account",
        component: lazy(() => import("./pages/openchain/children/account")),
        title: "链账户管理"
      },
      {
        path: "/openchain/cert",
        component: lazy(() => import("./pages/openchain/children/cert")),
        title: "证书及开发组件"
      }
    ]
  },
  /** 区块链市场 */
  {
    path: "/market",
    component: lazy(() => import("./pages/market")),
    title: "区块链市场",
    shotTitle: "市场",
    icon: "shopping"
  },
  /** 应用详情 */
  {
    path: "/application/:app_id",
    component: lazy(() => import("./pages/market/application")),
    title: "应用详情",
    hideInMenu: true
  },
  /** 合约详情 */
  {
    path: "/contract/:contract_id",
    component: lazy(() => import("./pages/market/contract")),
    title: "合约详情",
    hideInMenu: true
  },
  /** 账号中心模块路由配置 */
  {
    path: "/account",
    redirect: "/account/profile",
    title: "账号中心",
    shotTitle: "账号",
    icon: "user",
    component: lazy(() => import("./pages/account")),
    children: [
      {
        path: "/account/profile",
        component: lazy(() => import("./pages/account/children/profile")),
        title: "基本信息"
      },
      {
        path: "/account/fee",
        component: lazy(() => import("./pages/account/children/fee")),
        title: "我的账户"
      },
      {
        path: "/account/address",
        component: lazy(() => import("./pages/account/children/address")),
        title: "收件地址"
      },
      {
        path: "/account/preference",
        component: lazy(() => import("./pages/account/children/preference")),
        title: "首选项设置"
      }
    ]
  },
  /** 消息中心模块路由配置 */
  {
    path: "/notice",
    component: lazy(() => import("./pages/notice")),
    title: "消息中心",
    shotTitle: "消息",
    icon: "xinxi",
    redirect: "/notice/all",
    children: [
      {
        path: "/notice/all",
        component: lazy(() => import("./pages/notice/children/all")),
        title: "所有消息"
      },
      {
        path: "/notice/readed",
        component: lazy(() => import("./pages/notice/children/readed")),
        title: "已读消息"
      },
      {
        path: "/notice/unread",
        component: lazy(() => import("./pages/notice/children/unread")),
        title: "未读消息"
      },
      {
        path: "/notice/detail/:notice_id",
        component: lazy(() => import("./pages/notice/children/detail")),
        title: "消息详情"
      }
    ]
  },
  {
    path: "/guide",
    component: lazy(() => import("./pages/guide")),
    title: "BaaS平台新手引导",
    hideInMenu: true
  },
  {
    path: "/order/chain",
    component: lazy(() => import("./pages/order/chain")),
    title: "订单支付",
    hideInMenu: true
  },
  {
    path: "/buy/chain",
    component: lazy(() => import("./pages/buy/chain")),
    title: "购买联盟链",
    hideInMenu: true
  },
  {
    path: "/chains/:network/block/:blockhash",
    component: lazy(() => import("./pages/BlockchainBrowserPages/BlockPage")),
    title: "区块详情",
    hideInMenu: true
  },
  {
    path: "/chains/:network/transaction/:txid",
    component: lazy(() => import("./pages/BlockchainBrowserPages/TransactionPage")),
    title: "存证详情",
    hideInMenu: true
  },
  {
    path: "/chains/:network",
    component: lazy(() => import("./pages/BlockchainBrowserPages/BlockchainBrowserPage")),
    title: "区块链",
    hideInMenu: true
  }
];

export default routes;
