import React, { CSSProperties } from "react";
import Flex from "../Flex";
import "./index.scss";

type CustomStyle = {
  style?: CSSProperties;
};

/**
 * ### 可设置阴影高度的盒子容器
 *
 * Props
 *
 * |参数 | 说明 | 类型 |默认值 |
 * | :- | :- | :- | :- |
 * | level | 阴影高度 | `number` | 0 |
 * | hoverLevel | 鼠标悬停时的阴影高度 | `number` | 0 |
 * | variant | 变体 | `'box'`阴影盒子 / `'outlined'`带边框的盒子  | 'box' |
 * | round | 是否圆角 | `boolean`  | false |
 * | onClick | 点击事件 | `() => void` | - |
 */

type BoxProps = {
  level?: number;
  hoverLevel?: number;
  variant?: "elevation" | "outlined";
  round?: boolean;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
} & CustomStyle;

type BoxHeaderProps = {
  title: string | React.ReactNode;
  icon?: React.ReactNode;
  extra?: React.ReactNode;
  border?: boolean;
} & CustomStyle;

interface BoxType extends React.FC<BoxProps> {
  Header: React.FC<BoxHeaderProps>;
  Content: React.FC<CustomStyle>;
}
const Box: BoxType = ({
  level,
  hoverLevel,
  variant = "box",
  round = false,
  className,
  onClick,
  children,
  style = {}
}) => {
  // 根据条件拼接css选择器
  let clsx = ["box-root"];
  if (level) {
    clsx.push(`elevation${level}`);
  }
  if (hoverLevel) {
    clsx.push(`hover-elevation${hoverLevel}`);
  }
  if (variant === "outlined") {
    clsx.push("outlined");
  }
  if (round) {
    clsx.push("rounded");
  }
  if (className) {
    clsx.push(className);
  }

  // 拼接style
  let _style: CSSProperties = { ...style };
  if (hoverLevel || onClick) {
    _style.cursor = "pointer";
  }

  return (
    <div style={_style} className={clsx.join(" ")} onClick={onClick}>
      {children}
    </div>
  );
};

const BoxHeader: React.FC<BoxHeaderProps> = ({ title, icon, extra, style, border = true }) => {
  return (
    <Flex className={`box-header${border ? " border" : ""}`} style={style}>
      {icon && <div className="box-header__icon">{icon}</div>}
      <Flex.Item className="box-header__title">{title}</Flex.Item>
      {extra && <div className="box-header__extra">{extra}</div>}
    </Flex>
  );
};

const BoxContent: React.FC<CustomStyle> = ({ style, children }) => {
  return (
    <div className="box-content" style={style}>
      {children}
    </div>
  );
};

Box.Header = BoxHeader;
Box.Content = BoxContent;

export default Box;
