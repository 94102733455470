import * as userApi from "@/services/auth";
import * as Auth from "@/libs/auth";
import * as noticeApi from "@/services/notice";
import actions from "./actions";
import chainActions from "../union/actions";

/**
 * 登录
 *
 * @param data
 */
const login = (data: any) => async (dispatch: any) => {
  try {
    const resp = await userApi.login(data);
    const { status, message, token, user } = resp;

    if (status === 200) {
      if (token) {
        Auth.saveToken(token); // 缓存token
        dispatch(actions.setAuthToken(token)); // 将token保存到store
      } else {
        return Promise.reject("Token does not exist");
      }

      if (user) {
        Auth.saveCurrentUser(user); // 缓存用户信息
        dispatch(actions.setUserInfo(user)); // 将用户信息保存到store
      } else {
        return Promise.reject("UserInfo does not exist");
      }

      return Promise.resolve();
    } else {
      return Promise.reject(message);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * 更新未读消息数量
 */
const updateUnreadNotice = () => async (dispatch: any) => {
  try {
    const { status, count } = await noticeApi.getUnreadCount();
    if (status === 200) {
      dispatch(actions.setUnreadNotice(count));
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 注销
 */
const logout = () => async (dispatch: any) => {
  try {
    await userApi.logout();

    setTimeout(function () {
      Auth.removeToken(); // 移除缓存的token
      Auth.removeCurrentUser(); // 移除缓存的用户信息
      dispatch(actions.setAuthToken()); // 移除store中的token
      dispatch(actions.setUserInfo()); // 移除store中的用户信息
      dispatch(actions.setUnreadNotice(0)); // 清空未读消息数量
    });

    setTimeout(function () {
      dispatch(chainActions.setCurrentUnion()); // 移除当前联盟信息
      dispatch(chainActions.setUnionList([])); // 移除我的联盟列表
    }, 500);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  login,
  logout,
  updateUnreadNotice
};
