import * as React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "@/libs/prototype";
import App from "./App";
import "antd/dist/antd.css";
import "nprogress/nprogress.css";
import "./styles/index.scss";
import { initStore } from "./state";
const store = initStore();

const rootDOM = document.getElementById("root");

if (rootDOM) {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootDOM
  );
}
