import http from "../libs/http";

/**
 * 用户登录
 *
 * @param data
 */
export function login(data: DPBaaS.LoginParams) {
  return http.post<{
    token: string;
    user: any;
  }>("/auth/login", data);
}

/**
 * 注销登录
 */
export function logout() {
  return http.post("/auth/logout");
}

/**
 * 用户注册
 *
 * @param data
 */
export function register(data: DPBaaS.RegisterParams) {
  return http.post<{ user: any }>("/auth/register", data);
}

/**
 * 发送注册验证码
 *
 * @param phoneNumber
 */
export function sendRegCode(phoneNum: string) {
  return http.post("/auth/sendRegCode", { phoneNum });
}
