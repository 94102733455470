import * as chainApi from "@/services/chain";
import actions from "./actions";

/**
 * 获取通道、链信息
 *
 * @param data
 */
const getChannelInfo = (network: string) => async (dispatch: any) => {
  if (!network) return;
  try {
    dispatch(actions.setNetwork(network));

    const resp = await chainApi.getChannelInfo(network);
    const { status, message, channels, chain } = resp;

    if (status === 200) {
      if (channels && channels.length > 0) {
        dispatch(actions.setChannels(channels));
        dispatch(actions.setChannelGenesisHash(channels[0].channel_genesis_hash));
      }
      if (chain) {
        dispatch(actions.setChain(chain));
      }

      return Promise.resolve();
    } else {
      return Promise.reject(message);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export default {
  getChannelInfo
};
