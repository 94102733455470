import React from "react";
import styles from "./index.module.scss";

/**
 * ### 欢迎语组件
 *
 * Props
 *
 * |参数 | 说明 | 类型 |默认值 |
 * | :- | :- | :- | :- |
 * | title | 欢迎语标题 | `string` | - |
 * | content | 欢迎语内容 | `string` | - |
 *
 */
const Welcome: React.FC<{
  title: string;
  content: string;
}> = ({ title, content, children }) => {
  return (
    <section className={styles.root}>
      <h1 className={styles.title}>{title}</h1>
      <p className={styles.content}>{content}</p>
      {children}
    </section>
  );
};
export default Welcome;
