import http from "../libs/http";

/**
 * 获取当前用户所加入的联盟/包括待加入的联盟
 *
 */
export const query = () => {
  return http.get<{ unions: DPBaaS.Union[] }>("/sys/union/list");
};

/**
 * 新增联盟
 *
 * @param data
 */
export const add = (data: DPBaaS.AddUnionParams) => {
  return http.post("/sys/union/create", data);
};

/**
 * 获取某个联盟下的所有组织/用户信息
 *
 * @param unionId
 */
export const queryOrgList = (unionId: string | number) => {
  return http.get<{ orgs: DPBaaS.Org[] }>(`/sys/union/${unionId}/orgs`);
};

/**
 * 获取联盟的邀请机构列表
 *
 * @param unionId
 */
export const queryOrgApproveList = (unionId: number) => {
  console.log("queryOrgApproveList");
};

/**
 * 机构审批
 * 同意/拒绝某个用户加入某个组织
 *
 * @param unionId 联盟ID
 * @param unionId 联盟ID
 * @param action 审批类型  `agree`同意  | `reject`拒绝
 */
export const orgApproval = (userId: number, unionId: number, action: "agree" | "reject") => {
  return http.post(`/sys/union/${action}`, {
    userId,
    unionId
  });
};

/**
 * 通过邀请码加入联盟
 *
 * @param inviteCode 邀请码
 */
export const joinWithInvite = (inviteCode: string) => {
  return http.post("/sys/union/join", {
    inviteCode
  });
};

/**
 * 发送邀请码（邀请组织加入联盟）
 *
 * @param inviteCode 邀请码
 * @param unionName 联盟名称
 * @param orgName 受邀机构名称
 * @param phoneNum 受邀机构手机号
 */
export const sendInviteCode = (data: { inviteCode: string; unionName: string; orgName: string; phoneNum: string }) => {
  return http.post("/sys/union/SendInviteCode", data);
};
