/**
 * 封装阿里的iconfont图标组件
 *
 * 使用
 *  - <Icon>{iconName}</Icon>
 */
import React, { CSSProperties } from "react";
import Flex from "./Flex";

const Icon: React.FC<{
  fontSize?: number;
  color?: string;
  style?: CSSProperties;
}> & {
  Radio: React.FC<{ color?: string }>;
} = ({ fontSize, color, style, children }) => {
  if (typeof children !== "string") {
    console.error(`Icon's children must be a string`);
    return null;
  }

  const _style: CSSProperties = {
    fontSize,
    color,
    ...style
  };

  return (
    <svg className="icon" style={_style} aria-hidden={true} fill={color}>
      <use xlinkHref={`#${children}`} />
    </svg>
  );
};

const RadioIcon: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <Flex style={{ height: 40, width: 40, flexShrink: 0 }} align="center" jusify="center">
      <svg className="icon" style={{ width: 18, height: 18 }} viewBox="0 0 1024 1024" version="1.1" p-id="1140">
        <path
          d="M511.392323 217.225051c-82.075152 2.534141-151.518384 31.573333-207.075555 87.130505-55.544242 55.557172-84.596364 125.000404-88.384647 208.329697 2.534141 83.329293 31.573333 152.772525 87.130505 208.329696s125.000404 84.596364 208.329697 87.130506c83.330586-2.534141 152.772525-31.573333 208.329697-87.130506 55.555879-55.557172 84.596364-125.000404 87.129212-208.329696-2.532848-83.329293-31.573333-152.772525-87.129212-208.329697-55.558465-55.557172-125.000404-84.596364-208.329697-87.130505z m0-53.023031c98.481131 2.521212 180.557576 36.615758 246.211233 102.270707 65.656242 65.654949 99.749495 147.730101 102.273292 246.212526-2.522505 98.482424-36.615758 180.557576-102.273292 246.212525-65.653657 65.654949-147.730101 99.749495-246.211233 102.272-98.482424-2.521212-180.557576-36.615758-246.212525-102.272C199.524848 693.244121 165.430303 611.167677 162.909091 512.685253c2.521212-98.482424 36.615758-180.557576 102.270707-246.212526 65.654949-65.654949 147.730101-99.749495 246.212525-102.270707z m0 147.730101c56.825535 1.254141 103.537778 21.462626 141.420606 59.332525 37.868606 37.882828 58.078384 84.596364 59.331233 141.420607-1.252848 56.824242-21.462626 103.537778-59.331233 141.407676-37.884121 37.882828-84.596364 58.091313-141.420606 59.344162-56.824242-1.254141-103.537778-21.462626-141.407677-59.344162-37.882828-37.869899-58.091313-84.583434-59.345454-141.407676 1.254141-56.824242 21.462626-103.537778 59.345454-141.420607 37.869899-37.869899 84.583434-58.078384 141.407677-59.332525z"
          fill={color ? color : "#353535"}
        />
      </svg>
    </Flex>
  );
};

Icon.Radio = RadioIcon;
export default Icon;
