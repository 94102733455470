import { getToken } from "@/libs/auth";
import * as types from "./types";

export interface UserState {
  userInfo?: any;
  authToken?: string | null;
  unreadNotice: number;
}

const initialState: UserState = {
  unreadNotice: 0,
  authToken: getToken()
};

const userReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.USER_INFO:
      return {
        ...state,
        userInfo: action.payload
      };

    case types.AUTH_TOKEN:
      return {
        ...state,
        authToken: action.payload
      };

    case types.UNREAD_NOTICE:
      return {
        ...state,
        unreadNotice: action.payload
      };

    default:
      return state;
  }
};

export default userReducers;
